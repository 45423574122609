<template>
  <v-container
    fluid
    class="d-flex align-center"
    style="height: 100%;"
  >
    <!-- СОЗДАННЫЕ ДОСТУПЫ -->
    <v-dialog
      v-model="showResponse"
      width="700"
      scrollable
    >
      <v-card>
        <v-card-text class="pt-4">
          <v-list two-line>
            <v-list-item
              v-for="(item, i) in response"
              :key="i"
            >
              <v-list-item-icon>
                <v-icon
                  v-if="item.created"
                  color="success"
                >
                  mdi-check
                </v-icon>
                <v-icon
                  v-else-if="item.updated"
                  color="warning"
                >
                  mdi-swap-horizontal
                </v-icon>
                <v-icon
                  v-else
                  color="error"
                >
                  mdi-close
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.email" />
                <v-list-item-subtitle v-if="item.created">
                  Создан
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else-if="item.updated">
                  Обновлен c free или trial на base
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else>
                  Есть активная подписка
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="showResponse = false;"
          >
            Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- СОЗДАННЫЕ ДОСТУПЫ КОНЕЦ -->

    <v-row>
      <!-- ФОРМА -->
      <v-col v-bind="colAttributes">
        <!-- РЕФЕРАЛ -->
        <v-text-field
          v-model="referal"
          dense
          outlined
          label="Реферал"
          hide-details="auto"
          :error="touched && !emailPattern.test(referal)"
          :error-messages="touched && !emailPattern.test(referal) ? 'Нет почты' : null"
        />
        <!-- РЕФЕРАЛ КОНЕЦ -->

        <!-- ДОСТУПЫ -->
        <v-textarea
          v-model="rawAccesses"
          dense
          outlined
          label="Доступы"
          class="mt-4"
          hide-details="auto"
          :error="touched && accesses.length === 0"
          :error-messages="touched && accesses.length === 0 ? 'Нет почты' : null"
          @input="handleAccessesInput"
        />
        <!-- ДОСТУПЫ КОНЕЦ -->

        <!-- ЗАГРУЗИТЬ CSV -->
        <v-btn
          class="mt-2"
          @click="openFileUploader"
        >
          загрузить доступы csv
        </v-btn>
        <!-- ЗАГРУЗИТЬ CSV КОНЕЦ -->

        <!-- СКРЫТЫЙ ФАЙЛОВЫЙ ИНПУТ -->
        <input
          v-show="false"
          ref="inputUpload"
          type="file"
          accept=".csv"
          @change="handleInputUpload"
        >
        <!-- СКРЫТЫЙ ФАЙЛОВЫЙ ИНПУТ КОНЕЦ -->
      </v-col>
      <!-- ФОРМА КОНЕЦ -->

      <!-- ЗАРЕГИСТРИРОВАТЬ -->
      <v-col
        v-bind="colAttributes"
        class="text-center"
      >
        <v-btn
          color="primary"
          :loading="loading"
          @click="register"
        >
          Зарегистрировать
        </v-btn>
      </v-col>
      <!-- ЗАРЕГИСТРИРОВАТЬ КОНЕЦ -->

      <!-- СОЗДАННЫЕ ДОСТУПЫ -->
      <v-col
        v-bind="colAttributes"
        class="text-center"
      >
        <v-btn
          v-if="Array.isArray(response)"
          text
          @click="showResponse = true"
        >
          Созданные доступы
        </v-btn>
      </v-col>
      <!-- СОЗДАННЫЕ ДОСТУПЫ КОНЕЦ -->
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Accesses',

  data() {
    return {
      colAttributes: {
        cols: '12',
        md: '6',
        offsetMd: '3',
        lg: '4',
        offsetLg: '4',
      },
      referal: '',
      rawAccesses: '',
      accesses: [],
      emailPattern: /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/,
      touched: false,
      loading: false,
      response: null,
      showResponse: null,
    };
  },

  computed: {
    formIsInvalid() {
      return !this.emailPattern.test(this.referal) || this.accesses.length === 0;
    },
  },

  watch: {
    referal() {
      this.touched = false;
    },
  },

  methods: {
    openFileUploader() {
      this.$refs.inputUpload.value = '';
      this.$refs.inputUpload.click();
    },

    handleInputUpload() {
      const THIS = this;
      const file = this.$refs.inputUpload.files[0];
      if (file) {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = () => {
          THIS.rawAccesses = reader.result;
          THIS.handleRawString('accesses', reader.result);
        };
      }
    },

    handleAccessesInput(value) {
      this.touched = false;
      this.handleRawString('accesses', value);
    },

    handleRawString(property, value) {
      this[property] = [];
      const parsedEmails = this.parseEmails(value);
      if (Array.isArray(parsedEmails) && parsedEmails.length) {
        this[property] = parsedEmails;
      }
    },

    parseEmails(rawString) {
      const emails = [];
      const lines = rawString.toString().split(/(\r\n|\r|\n)/);
      if (lines.length === 0) return [];

      lines.forEach((_line) => {
        if (!_line.trim()) return;

        const isValidEmail = this.emailPattern.test(_line);
        if (isValidEmail) {
          emails.push(_line);
        }
      });

      return emails;
    },

    async register() {
      if (this.formIsInvalid) {
        this.touched = true;
        return;
      }

      try {
        this.loading = true;
        this.response = null;
        const response = await this.api.post('/admin/users/promo-access', {
          ref: this.referal,
          accesses: this.accesses,
        });

        const { status, data } = response;
        if (status === 200 && data.success) {
          this.response = data.data;
        }

        this.loading = false;
      } catch (_) {
        this.loading = false;
      }
    },
  },
};
</script>
